define(["dojo-proxy-loader?name=dojo/_base/declare!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "./SignupForm", "dojo-proxy-loader?name=dojo/query!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/window", "dojo-proxy-loader?name=dojo/on!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/Deferred!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/sniff!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/_CustomStyleRulesMixin"], function (declare, _WidgetBase, SignupForm, query, lang, win, on, domGeom, domConstruct, domStyle, domClass, all, Deferred, html, has, _CustomStyleRulesMixin) {
  // For strict content, we store one of these values as the font to apply to heading and paragraphs
  var FONT_MAP = {
    "arial": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
    "comic_sans": "'Comic Sans MS', 'Marker Felt-Thin', Arial, sans-serif",
    "courier_new": "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace",
    "georgia": "Georgia, Times, 'Times New Roman', serif",
    "helvetica": "'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif",
    "lucida": "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
    "tahoma": "Tahoma, Verdana, Segoe, sans-serif",
    "times_new_roman": "'Times New Roman', Times, Baskerville, Georgia, serif",
    "trebuchet_ms": "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
    "verdana": "Verdana, Geneva, sans-serif"
  };
  return declare([_WidgetBase, _CustomStyleRulesMixin], {
    env: "prod",
    version: "1.0",
    template: 1,
    appDomain: "",
    honeypotFieldName: "",
    isPreview: false,
    maxWidth: 768,
    // media query break point
    mobileView: false,
    // true if window width is < maxWidth
    iframe: null,
    frameDoc: null,
    layoutCssNode: null,
    // Reference to the link dom that loads the css
    customCssNode: null,
    // custom form styles from editor
    imageUrl: null,
    imageEdgeToEdge: null,
    // User-configurable attributes
    config: {},
    // Config that confines to what the server model generates
    subscribeUrl: "#",
    constructor: function (props) {
      if (!props.iframe || !props.iframe.tagName || props.iframe.tagName.toLowerCase() !== "iframe") {
        throw new Error("You must specify an iframe attribute to an iframe element");
      }
    },
    postMixInProperties: function () {
      // Pull in any attributes from the config
      if (this.config.template) {
        this.template = this.config.template;
      }
      if (this.config.version) {
        this.version = this.config.version;
      }
      if (this.config.imageUrl) {
        this.imageUrl = this.config.imageUrl;
      }
      if (this.config.hasOwnProperty("imageEdgeToEdge")) {
        this.imageEdgeToEdge = this.config.imageEdgeToEdge;
      }
      if (this.config.styles) {
        this.styles = this.config.styles;
      }
      if (this.styles.hasOwnProperty("modal") && this.styles.modal.hasOwnProperty("max_width")) {
        this.maxWidth = this.styles.modal.max_width - 20;
      }
      this.frameDoc = this.iframe.contentWindow.document;
    },
    postCreate: function () {
      this.signupForm = new SignupForm({
        config: this.config,
        subscribeUrl: this.subscribeUrl,
        honeypotFieldName: this.honeypotFieldName,
        isPreview: this.isPreview
      });
      this.frameDoc.write('<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/></head><body></body></html>');
      this.frameDoc.close();
      this.signupForm.placeAt(this.frameDoc.body);
      this.signupForm.startup();

      // Update the height when CSS is finished loading
      all([this.loadCommonCss(), this.loadLayoutCss(), this.loadCustomCss()]).then(lang.hitch(this, "updateDocHeight"));
      on(window, "resize", lang.hitch(this, function () {
        this.updateDocHeight();
        this._addMobileClass(this._isMobileView());
      }));
      this.signupForm.on("resizeFrame", lang.hitch(this, function () {
        this.updateDocHeight();
      }));
      if (this.config.modalVariation === "fixed") {
        this._overrideDescriptionForFixedLayout();
        this._showPlaceholderTextForEmail();
      }
    },
    /**
     * For fixed layout signup forms, we want the description to be stripped out of any markup. For signup forms
     * using the strict content styling, we want to wrap it around a <p> to get all global color and font-sizes
     *
     * @private
     */
    _overrideDescriptionForFixedLayout: function () {
      var descriptionHTML = this._cleanText(this.config.description);
      if (this.signupForm.isStrictContent()) {
        descriptionHTML = "<p>" + descriptionHTML + "</p>";
      }
      // Truncate and set description text
      html.set(this.signupForm.descriptionContainer, descriptionHTML);
    },
    startup: function () {
      this.inherited(arguments);
      this.mobileView = this._isMobileView();
      this._addMobileClass(this.mobileView);
    },
    _cleanText: function (string) {
      // Char limit for passed string
      var charLimit = 50;

      // Strip html tags
      string = string.replace(/<\/?[^>]+(>|$)/gi, "");

      // Replace nbsp with actual spaces
      string = string.replace(/&nbsp;/gi, " ");
      if (string.length > charLimit) {
        return string.substring(0, charLimit) + "...";
      }
      return string;
    },
    /**
     * Add a class when in mobile view to be able to add custom mobile styles
     *
     * @param {Boolean} isMobileView - true/false depending on window width
     *
     * @private
     */
    _addMobileClass: function (isMobileView) {
      var modalContentNode = this.frameDoc.getElementsByClassName("modalContent")[0];
      if (isMobileView) {
        domClass.add(modalContentNode, "modalContent--mobile");
      } else {
        domClass.remove(modalContentNode, "modalContent--mobile");
      }
    },
    /**
     * For the fixed layout, we don't have a label, so we're going to rely on showing a placeholder on the email input.
     *
     * For mobile view, we're already showing a banner and that has a label, so we can skip over adding the placeholder
     *
     * @private
     */
    _showPlaceholderTextForEmail: function () {
      // Add placeholder text for desktop view of fixed format
      this.mobileView = this._isMobileView();
      var emailField = this.signupForm.getEmailField();
      if (emailField) {
        emailField.toggleEmailPlaceholder(!this.mobileView);
      }
    },
    docHeight: function () {
      return domGeom.getContentBox(this.signupForm.domNode).h;
    },
    updateDocHeight: function () {
      if (this.config.modalVariation === "fixed") {
        return;
      }
      // FF throws an error when you try to get the height of a hidden element
      // See: https://bugs.dojotoolkit.org/ticket/12987)
      try {
        domStyle.set(this.iframe, "height", this.docHeight() + "px");
        domStyle.set(query(".mc-layout__modalContent")[0], "max-height", this.docHeight() + "px");
        // fixed format requires 1 extra px due to 1px top border
        if (this.config.modalVariation === "fixed") {
          domStyle.set(query(".mc-layout__modalContent")[0], "max-height", this.docHeight() + 1 + "px");
        }
      } catch (e) {}
    },
    _isMobileView: function () {
      return win.getBox().w <= this.maxWidth ? true : false;
    },
    loadCommonCss: function () {
      var commonDeferred = new Deferred();
      var common = this.frameDoc.createElement("link");
      common.rel = "stylesheet";
      common.type = "text/css";
      if (this.env === "dev") {
        var pathPrefix = this.appDomain === "" ? "" : "//" + this.appDomain;
        common.href = pathPrefix + "/css/signup-forms/popup/common.css";
      } else {
        common.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/common.css";
      }
      common.media = "all";
      on(common, "load", function () {
        commonDeferred.resolve();
      });
      this.frameDoc.getElementsByTagName("head")[0].appendChild(common);
      return commonDeferred.promise;
    },
    loadLayoutCss: function () {
      if (this.layoutCssNode) {
        domConstruct.destroy(this.layoutCssNode);
      }
      var layoutDeferred = new Deferred();
      this.layoutCssNode = this.frameDoc.createElement("link");
      this.layoutCssNode.rel = "stylesheet";
      this.layoutCssNode.type = "text/css";
      if (this.env === "dev") {
        var pathPrefix = this.appDomain === "" ? "" : "//" + this.appDomain;
        this.layoutCssNode.href = pathPrefix + "/css/signup-forms/popup/layout-" + this.template + ".css";
        if (this.config.modalVariation === "fixed") {
          this.layoutCssNode.href = pathPrefix + "/css/signup-forms/popup/layout-fixed.css";
          this.template = 1;
        }
      } else {
        this.layoutCssNode.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/layout-" + this.template + ".css";
        if (this.config.modalVariation === "fixed") {
          this.layoutCssNode.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/layout-fixed.css";
          this.template = 1;
        }
      }
      this.layoutCssNode.media = "all";
      on(this.layoutCssNode, "load", function () {
        layoutDeferred.resolve();
      });
      this.frameDoc.getElementsByTagName("head")[0].appendChild(this.layoutCssNode);
      return layoutDeferred.promise;
    },
    /*
     * Helper to look up the font-family css value based on the font key we store.
     * Defaults to helvetica when we can't find one.
     *
     * @returns {string}
     * @private
     */
    _getFontFamilyByKey: function (key) {
      return FONT_MAP[key] ? FONT_MAP[key] : FONT_MAP["helvetica"];
    },
    loadCustomCss: function () {
      if (this.customCssNode) {
        domConstruct.destroy(this.customCssNode);
      }
      var customDeferred = new Deferred();
      this.customCssNode = this.createStyleNode(this.frameDoc);
      var sheet = this.getStyleSheet(this.customCssNode);
      if (this.styles) {
        for (var el in this.styles) {
          if (this.styles.hasOwnProperty(el)) {
            switch (el) {
              case "button":
                // eslint-disable-next-line guard-for-in
                for (var prop in this.styles[el]) {
                  switch (prop) {
                    case "color":
                      this.addCSSRule(sheet, ".button", "background-color:" + this.styles[el][prop] + ";");
                      break;
                    case "hover_color":
                      this.addCSSRule(sheet, ".button:hover", "background-color:" + this.styles[el][prop] + ";");
                      break;
                    case "text_color":
                      this.addCSSRule(sheet, ".button", "color:" + this.styles[el][prop] + ";");
                      break;
                    case "alignment":
                      if (this.styles[el][prop] === "right") {
                        this.addCSSRule(sheet, ".button", "float:right;");
                      } else if (this.styles[el][prop] === "center") {
                        this.addCSSRule(sheet, ".button", "float:none;margin-left:auto;margin-right:auto;");
                        if (has("ie") <= 9) {
                          this.addCSSRule(sheet, ".button", "display:table-cell;");
                          this.addCSSRule(sheet, ".content__button", "margin-left:auto;margin-right:auto;display:table;");
                        } else {
                          this.addCSSRule(sheet, ".button", "display:table;");
                        }
                      } else {
                        this.addCSSRule(sheet, ".button", "float:left;");
                      }
                      break;
                    case "style":
                      if (this.styles[el][prop] === "full") {
                        this.addCSSRule(sheet, ".button", "width:100%;");
                      }
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "labels":
                if (!this.signupForm.isStrictContent()) {
                  // eslint-disable-next-line guard-for-in
                  for (var p in this.styles[el]) {
                    switch (p) {
                      case "color":
                        this.addCSSRule(sheet, "label", "color:" + this.styles[el][p] + ";");
                        break;
                      case "font":
                        this.addCSSRule(sheet, "label", "font-family:" + this.styles[el][p] + ";");
                        break;
                      default:
                        break;
                    }
                  }
                }
                break;
              case "headingColor":
                this.addCSSRule(sheet, ".strictContent h1, .strictContent h2, .strictContent h3, .strictContent h4", "color:" + this.styles[el] + ";");
                break;
              case "paragraphColor":
                this.addCSSRule(sheet, ".strictContent p, .strictContent .helpText, label", "color:" + this.styles[el] + ";");
                break;
              case "headingFont":
                this.addCSSRule(sheet, ".strictContent h1, .strictContent h2, .strictContent h3, .strictContent h4", "font-family:" + this._getFontFamilyByKey(this.styles[el]) + ";");
                break;
              case "paragraphFont":
                this.addCSSRule(sheet, ".strictContent p, .strictContent .helpText, label", "font-family:" + this._getFontFamilyByKey(this.styles[el]) + ";");
                break;
              case "backgroundColor":
                this.addCSSRule(sheet, ".modalContent", "background-color:" + this.styles[el] + ";");
                break;
              default:
                break;
            }
          }
        }
      }
      if (this.imageUrl && this.template !== 1) {
        this.addCSSRule(sheet, ".modalContent__image", "background-image:url(" + this.imageUrl + ");");
      }
      if (this.imageEdgeToEdge) {
        this.addCSSRule(sheet, ".modalContent__image", "background-size:cover;");
      }
      customDeferred.resolve();
      return customDeferred.promise;
    }
  });
});