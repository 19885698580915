define(["dojo-proxy-loader?name=dojo/_base/declare!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/modal.html", "./SignupFormFrame", "./BannerFrame", "dojo-proxy-loader?name=dojo/query!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/Deferred!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/_CustomStyleRulesMixin", "dojo/NodeList-manipulate"], function (declare, _WidgetBase, _TemplatedMixin, template, SignupFormFrame, BannerFrame, query, lang, on, domConstruct, domStyle, keys, all, Deferred, domClass, _CustomStyleRulesMixin) {
  /**
   * Keep a reference of only one popup form that we add through the loader.
   */
  var currentPopup = null;
  return declare("PopupSignupForm", [_WidgetBase, _TemplatedMixin, _CustomStyleRulesMixin], {
    env: "prod",
    version: "1.0",
    templateString: template,
    appDomain: "",
    honeypotFieldName: "",
    isPreview: false,
    ignoreCookie: false,
    origOverflowValue: null,
    customCssNode: null,
    // custom modal styles from editor
    regularContentContainer: null,
    regularContentImage: null,
    // User-configurable attributes
    config: {},
    subscribeUrl: "#",
    popupDelay: 1000,
    popupFrequency: 365,
    popupOpacity: 0.65,
    closeLabel: "close",
    hasDoubleOptIn: false,
    postMixInProperties: function () {
      // Set attributes from the config
      if (this.config.popupOpacity) {
        this.popupOpacity = this.config.popupOpacity / 100; //  Config value is %
      }
      if (this.config.popupDelay) {
        if (isNaN(Number(this.config.popupDelay))) {
          this.popupDelay = this.config.popupDelay;
        } else {
          this.popupDelay = this.config.popupDelay * 1000; //  Config value is in seconds
        }
      }
      if (this.config.popupFrequency) {
        this.popupFrequency = this.config.popupFrequency;
      }
      if (this.config.styles) {
        this.styles = this.config.styles;
      }
      if (this.config.closeLabel) {
        this.closeLabel = this.config.closeLabel;
      }
      if (this.config.version) {
        this.version = this.config.version;
      }
      if (this.config.template) {
        this.template = this.config.template;
      }
      if (this.config.appDomain) {
        this.appDomain = this.config.appDomain;
      }
      if (this.config.hasDoubleOptIn) {
        this.hasDoubleOptIn = this.config.hasDoubleOptIn;
      }
    },
    placeAt: function () {
      if (currentPopup === null) {
        this.inherited(arguments);
      }
    },
    postCreate: function () {
      this.inherited(arguments);
    },
    startup: function () {
      this.inherited(arguments);
      if (currentPopup === null) {
        // eslint-disable-next-line consistent-this
        currentPopup = this;
      } else {
        return;
      }

      // Set banner, modal overlay and container to display none before we load anything to hide blink
      domStyle.set(this.bannerContainer, "display", "none");
      domStyle.set(this.modalOverlay, "display", "none");
      domStyle.set(this.modalContainer, "display", "none");

      // Don't load if the user has signed up or closed the modal
      if (this._skipShowingPopup()) {
        return;
      }
      this.bannerFrame = new BannerFrame({
        iframe: this.iframeBannerContainer,
        config: this.config,
        appDomain: this.appDomain
      });
      this.bannerFrame.startup();
      this.modalFrame = new SignupFormFrame({
        iframe: this.iframeModalContainer,
        config: this.config,
        subscribeUrl: this.subscribeUrl,
        honeypotFieldName: this.honeypotFieldName,
        env: this.env,
        appDomain: this.appDomain,
        isPreview: this.isPreview
      });
      this.modalFrame.startup();

      // Hook up show/hide events
      this._setupModal();
      all([this.loadModalCss(), this.loadCustomCss()]).then(lang.hitch(this, "openModal"));
    },
    openModal: function () {
      var _openModal = this._openModal.bind(this);
      // Event to detect exiting a page
      // NOTE: we have to remove the event listeners because they keep causing popups
      function handleMouseOut() {
        _openModal();
        document.removeEventListener("mouseleave", handleMouseOut, false);
      }
      function handleScrollMidway() {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight / 2) {
          _openModal();
          document.removeEventListener("scroll", handleScrollMidway, false);
        }
      }
      function handleScrollEnd() {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          _openModal();
          document.removeEventListener("scroll", handleScrollEnd, false);
        }
      }
      if (this.popupDelay === "exit") {
        if (window.navigator.userAgent.indexOf("Edge") > -1 || window.screen && window.screen.width < 640) {
          // IE and Edge don't play nice with exit intent, so fallback to 1 second delay
          setTimeout(_openModal, 1000);
        } else {
          document.firstElementChild.addEventListener("mouseleave", handleMouseOut, false);
        }
      } else if (this.popupDelay === "middle") {
        document.addEventListener("scroll", handleScrollMidway, false);
      } else if (this.popupDelay === "bottom") {
        document.addEventListener("scroll", handleScrollEnd, false);
      } else {
        setTimeout(_openModal, this.popupDelay);
      }
    },
    closeModal: function () {
      this._closeModal();
    },
    addBackgroundForMobilePreview: function () {
      domConstruct.place('<div class=\"mc-modal-website\"></div>', this.domNode, "last");
    },
    showModal: function () {
      // Hide mobile banner
      domStyle.set(this.bannerContainer, "display", "none");

      // Show Modal
      domStyle.set(this.modalOverlay, "visibility", "visible");
      domStyle.set(this.modalContainer, "visibility", "visible");
    },
    closeBanner: function () {
      if (this.isPreview) {
        return;
      }

      // Hide mobile banner
      domStyle.set(this.bannerContainer, "display", "none");

      // Close Modal
      this._closeModal();
    },
    _openModal: function () {
      domStyle.set(this.bannerContainer, "display", "block");
      domStyle.set(this.modalOverlay, "display", "block");
      domStyle.set(this.modalContainer, "display", "block");

      // Force a frame update before we start the animation. Fixes a height issue for FF.
      this.modalFrame.updateDocHeight();
      this.bannerFrame.updateDocHeight();
      domStyle.set(this.modalOverlay, "opacity", this.popupOpacity);
      domStyle.set(this.modalContainer, "opacity", 1);
      if (this.config.useMobileBanner === "N") {
        this.showModal();
      }
    },
    _closeModal: function () {
      if (this.isPreview) {
        domStyle.set(this.modalContainer, "display", "none");
        this.bannerFrame = new BannerFrame({
          iframe: this.iframeBannerContainer,
          config: this.config,
          appDomain: this.appDomain
        });
        this.bannerFrame.startup();
        this.modalFrame = new SignupFormFrame({
          iframe: this.iframeModalContainer,
          config: this.config,
          subscribeUrl: this.subscribeUrl,
          honeypotFieldName: this.honeypotFieldName,
          env: this.env,
          appDomain: this.appDomain,
          isPreview: this.isPreview
        });
        this.modalFrame.startup();
        if (this.modalFrame._isMobileView()) {
          domStyle.set(this.modalContainer, "visibility", null);
          domStyle.set(this.modalOverlay, "visibility", null);
        }

        // Hook up show/hide events
        this._setupModal();
        all([this.loadModalCss(), this.loadCustomCss()]).then(lang.hitch(this, "openModal"));
        return;
      }

      // Set cookie for sites other than mailchimp. (allows preview popup in wizard)
      if (window.location.href.indexOf("mailchimp.com") === -1) {
        this._setCookie();
      }
      // check if browser supports animation styles
      if (this.modalContainer.style.animation !== undefined && (this.config.modalVariation === "fixed" || this.config.modalVariation === "slide")) {
        try {
          // listen for animation
          this.modalContainer.addEventListener("webkitAnimationEnd", this._hideOverlay.bind(this));
          this.modalContainer.addEventListener("animationend", this._hideOverlay.bind(this));
          // add animation class to close modal
          domClass.add(this.modalContainer, "mc-modal--close");
        } catch (error) {
          this._hideOverlay();
        }
      } else {
        this._hideOverlay();
      }
    },
    _hideOverlay: function () {
      domStyle.set(this.modalOverlay, "opacity", 0);
      domStyle.set(this.modalOverlay, "display", "none");
      this._cleanup();
    },
    _cleanup: function () {
      domConstruct.destroy(this.domNode);
      domStyle.set(document.body, "overflow", this.origOverflowValue);
      currentPopup = null;
    },
    _setupModal: function () {
      // Capture the parent document body's overflow property so we can change/restore it.
      // When the popup opens, set the parent document body's overflow property to 'auto' to ensure
      // the page scrolls when the popup height exceeds page height.
      // When the popup closes, restore the original overflow property value.
      // eslint-disable-next-line
      this.origOverflowValue = dojo.getComputedStyle(document.body).overflow;
      domStyle.set(document.body, "overflow", "auto");

      // Add tap to text content if need be
      if (this.config.smsField && this.config.useTtt === "Y" && this.modalFrame._isMobileView()) {
        this._setupTtt();
      }

      // Add event listeners
      // Set banner button actions
      var bannerContent = this.bannerFrame.bannerContent;
      on(bannerContent.modalOpen, "click", lang.hitch(this, "closeBanner"));
      on(bannerContent.bannerClose, "click", lang.hitch(this, "showModal"));

      // On click close modal link
      on(query("[data-action='close-mc-modal']")[0], "click", lang.hitch(this, "closeModal"));

      // On press escape key, close modal.
      on(window.document, "keyup", lang.hitch(this, function (e) {
        if (e.keyCode === keys.ESCAPE) {
          this.closeModal();
        }
      }));
      on(this.modalFrame.frameDoc, "keyup", lang.hitch(this, function (e) {
        if (e.keyCode === keys.ESCAPE) {
          this.closeModal();
        }
      }));
      var smsOptOut = this.modalFrame.frameDoc.querySelector(".content__smsOptOut");
      on(smsOptOut, "click", lang.hitch(this, this.config.handleFormSubmit));
    },
    _setupTtt: function () {
      var smsInputContainer = this.modalFrame.frameDoc.querySelector(".content__smsInputContainer");
      var tttContainer = this.modalFrame.frameDoc.querySelector(".content__tttContainer");
      domStyle.set(smsInputContainer, "display", "none");
      domStyle.set(tttContainer, "display", "block");
      this.config.resizeFrame();
      var tttSubmitButton = tttContainer.querySelector(".content_tttSubmitButton");
      on(tttSubmitButton, "click", lang.hitch(this, function () {
        setTimeout(lang.hitch(this, this._closeModal), 1000);
      }));
      var tttOptOut = tttContainer.querySelector(".content__tttOptOut");
      on(tttOptOut, "click", lang.hitch(this, function () {
        domStyle.set(smsInputContainer, "display", "block");
        domStyle.set(tttContainer, "display", "none");
        this.config.resizeFrame();
      }));
    },
    loadModalCss: function () {
      var modalDeferred = new Deferred();
      var modal = document.createElement("link");
      modal.rel = "stylesheet";
      modal.type = "text/css";
      if (this.env === "dev") {
        var pathPrefix = this.appDomain === "" ? "" : "//" + this.appDomain;
        modal.href = pathPrefix + "/css/signup-forms/popup/modal.css";
        if (this.config.modalVariation === "slide") {
          modal.href = pathPrefix + "/css/signup-forms/popup/modal-slidein.css";
        } else if (this.config.modalVariation === "fixed") {
          modal.href = pathPrefix + "/css/signup-forms/popup/modal-fixed.css";
        }
      } else {
        modal.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/modal.css";
        if (this.config.modalVariation === "slide") {
          modal.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/modal-slidein.css";
        } else if (this.config.modalVariation === "fixed") {
          modal.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/modal-fixed.css";
        }
      }
      modal.media = "all";
      on(modal, "load", function () {
        modalDeferred.resolve();
      });
      document.getElementsByTagName("head")[0].appendChild(modal);
      return modalDeferred.promise;
    },
    loadCustomCss: function () {
      if (this.customCssNode) {
        domConstruct.destroy(this.customCssNode);
      }
      var customDeferred = new Deferred();
      this.customCssNode = this.createStyleNode(document);
      var sheet = this.getStyleSheet(this.customCssNode);
      if (this.styles) {
        for (var el in this.styles) {
          if (this.styles.hasOwnProperty(el)) {
            switch (el) {
              case "modal":
                // eslint-disable-next-line guard-for-in
                for (var prop in this.styles[el]) {
                  switch (prop) {
                    case "close_link_color":
                      this.addCSSRule(sheet, ".mc-closeModal", "color:" + this.styles[el][prop] + ";");
                      break;
                    default:
                      break;
                  }
                }
                break;
              default:
                break;
            }
          }
        }
      }

      // change width of modal for template 3 and 4
      if (this.template && (this.template === 3 || this.template === 4)) {
        this.addCSSRule(sheet, ".mc-modal", "width: 603px;");
      }
      customDeferred.resolve();
      return customDeferred.promise;
    },
    _setCookie: function () {
      var now = new Date();
      var delay = this.popupFrequency * 86400000; // Convert days to ms
      var expires_date = new Date(now.getTime() + delay);
      document.cookie = "MCPopupClosed=yes;expires=" + expires_date.toGMTString() + ";path=/";
    },
    _skipShowingPopup: function () {
      return !this.ignoreCookie && this._hasCookie();
    },
    _hasCookie: function () {
      var cks = document.cookie.split(";");
      for (var i = 0; i < cks.length; i++) {
        var parts = cks[i].split("=");
        if (parts[0].indexOf("MCPopupClosed") !== -1 || parts[0].indexOf("MCPopupSubscribed") !== -1) {
          return true;
        }
      }
      return false;
    }
  });
});